.letterDisplay {
    margin: 2px;
    text-align: center;
    align-items: center;
}

.fullDisplay {
    border: 2px solid #878a8c;
    cursor: pointer;
}

.emptyDisplay {
    border: 2px solid #d3d6da;
}

.unset {
    background-color: white;
    color: black;
}

.exact {
    background-color: var(--color-exact);
    color: white;
}

.unmatched {
    background-color: var(--color-unmatched);
    color: white;
}

.excluded {
    background-color: var(--color-exclude);
    color: white;
}

.letter {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}

.tooltip_button_wrapper button {
    height: 19px;
    width: 15px;
    cursor: pointer;
}

.exact_button {
    background-color: var(--color-exact);
}

.unmatched_button {
    background-color: var(--color-unmatched);
}

.exclude_button {
    background-color: var(--color-exclude);
}
