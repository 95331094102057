.wordleassistant {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.wordleassistantContent {
    display: contents;
}

.keyboardContainer {
    position: absolute;
    bottom: 0px;
    margin-bottom: 8px;
}