.key_button {
    background-color: #d3d6da;
    font-weight: bold;
    height: 58px;
    border-radius: 4px;
    margin-right: 6px;
    border: 0;
    cursor: pointer;
}

.default_button {
    font-size: 1.25em;
    width: 43px;
}

.unset_button {
    background-color: #d3d6da;
    color: black;
}

.exact_button {
    background-color: var(--color-exact);
    color: white;
}

.unmatched_button {
    background-color: var(--color-unmatched);
    color: white;
}

.excluded_button {
    background-color: var(--color-exclude);
    color: white;
}

.enter_button {
    font-size: 12px;
    width: 65px;
}

.delete_button {
    width: 65px;
    font-size: 1.25em;
}