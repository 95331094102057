.possibleWords {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}

.wordContainer {
    overflow-y: scroll;
    height: 100%;
}

.word-hover {
    cursor: pointer;
}

.word-hover:hover {
    text-decoration: underline;
}

